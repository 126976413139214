import React from 'react'
import Seo from '../../components/Seo'
import { useLangContext } from '../../context/lang.context'
import { seo } from './seo'
import IntroSection from './IntroSection/IntroSection'
import TransportAll from './TransportAll/TransportAll'

const Transport = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <IntroSection />
      <TransportAll />
    </>
  )
}

export default Transport
