import React from 'react'
import Layout from '../components/Layout'
import Transport from '../page/Transport/Transport'

const TransportPage = () => (
  <Layout>
    <Transport />
  </Layout>
)

export default TransportPage
