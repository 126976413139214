export const seo = {
  title: {
    pl: 'Transport Międzynarodowy | Firma Transportowa Wrocław | Cargonite',
    en: 'Transport',
  },
  description: {
    pl: 'Dedykowane rozwiązania, bezpośrednie dostawy z gwarancją szybkiego i bezpiecznego transportu. Usługi CARGONITE to najlepszy wybór. Sprawdź!',
    en: 'Looking for a dedicated solution, direct delivery with a guarantee of fast and safe transport? CARGONITE services will be the best choice.',
  },
}
