export const introSectionContent = {
  title: {
    pl: 'Transport Międzynarodowy Wrocław',
    en: 'Transport',
  },
  back: {
    pl: 'CARGONITE',
    en: 'CARGONITE',
  },
  desc: {
    pl: 'Organizujemy transport w modelu FTL i LTL. Realizujemy transport krajowy i międzynarodowy do wszystkich krajów w Europie.',
    en: 'We organize transport in the FTL and LTL model. We provide domestic and international transport to all countries in Europe.',
  },
}
